export default {
  START_OVER: 'Clear Filters',
  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',
  YOU_MAY_ALSO_LIKE:
    '{window.Convermax.isVehicleSelected() ? "Also fits your vehicle:" : window.Convermax.isTireRelated  ? "Also fits your tires:" : "You might also like:"}',

  SEARCH_BOX_PLACEHOLDER: 'Search our store...',
  LOAD_MORE_TEXT: 'Show All',
  PRODUCT_REPEATER_CLASS: 'grid',

  SELECT_YOUR_VEHICLE: 'Shop by Vehicle',
  GO_TEXT: 'Search',
  GARAGE_SIZE:
    '<span rt-if="this.size" class="garage-size cart-link__bubble cart-link__bubble--visible"><span class="cart-link__bubble-num">{{ size }}</span></span>',

  FITMENT_TABLE_VEHICLE_SPECIFIC: 'This Part Fits:',

  VERIFY_FITMENT_NOT_FIT:
    'This product may not fit, please contact us to confirm if this fits your submodel.',
  FITMENT_UNKNOWN_TITLE: '',
  FITMENT_UNKNOWN_TEXT: 'Please check description below or contact us to confirm fitment.',
  VERIFY_FITMENT_ICON: `<rt-virtual rt-if="this.fits === 'yes'" template="icons/check" />`,
};
